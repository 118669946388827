<template>
  <div>
    <v-row>
      <v-col lg="12">
         <zoom-selection :isAdmin="true"  @rowSelected="rowSelected($event)"  :height="100">></zoom-selection>
      </v-col>
     
    </v-row>
  </div>
</template>

<script>

import ZoomSelection from './ZoomSelection.vue';

export default {  
    components: {        
    ZoomSelection       
  },

   data() {
    return {
      parentID: 0,
      fzsID:0,
     
    };
  },

  methods: {
    rowSelected(rows) {
     this.fzsID=rows[0].FzsID;
         },
   
  },
};
</script>
