import Service from "../Service";
import Vue from "vue";
const resource = "zoomSelection/";

export default {
    save(prd, requestID) {
        return Service.post(resource + "save", prd, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    list(requestID) {
        return Service.post(resource + "list", {}, {
            params: { requestID }
        });
    }
    //pdf
};