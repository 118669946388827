<template>
  <s-crud @returnObject='refresh($event)'
    no-border
    title="Tamaños de Selección"
    :config="this.config"
    @save="saveDed($event)"
    add
    @clearForm="clearForm()"
    edit
    restore
    @rowSelected="rowSelected($event)" 
    remove
    :filter="filter"
   
  >
    <template scope="props">
      <v-container style="padding-top:0px;" v-if="props.item != null" class="pb-0">
        <v-row>
          <v-col cols="6" class="s-col-form">
            <s-text v-model="props.item.FzsDescription" label="Descripción" />
          </v-col>
        <v-col cols="12" class="s-col-form" sm="4" md="8" lg="4">
              <s-select-definition v-model="props.item.FzsStatus" :def="1323" label="Estado">
                </s-select-definition>
            </v-col> 
        </v-row>
      </v-container>
    </template>
    <template v-slot:FzsStatus="{ row }">
        <v-chip style="margin:0px" x-small :color="row.FzsStatus == 1 ? 'success' : 'error'">
            {{ row.FzsStatus == 1 ? "Activo" : "Inactivo" }}
        </v-chip>
      </template> 
  </s-crud>
</template>

<script>
import _service from "@/services/FrozenProduction/ZoomSelectionService";
export default {
  props: {
    fzsID: 0,   
    fzsDescription: "",
  },
  data: () => ({
    dedValue:0,
      config: {
      title: "Tamaños de Selección",
      service: _service,
      model: {
        
        FzsID: "ID",
        FzsDescription: "string",
        SecStatus: "status",
        FzsStatus: "status",
      },
      headers: [
          {
            //
          text: "ID",
          value: "FzsID",
          width: "5%",
          align: "end",
          sorteable: true,
        },
        {
          text: "Descripción",
          value: "FzsDescription",
          width: "55%",
          sorteable: true,
        },
             
        {
          text: "Estado",
          value: "FzsStatus",
          width: "5%",
          align: "center",
          sorteable: false,
        },
      ],
    },
  }),

  computed: {
   
    filter() {
      return { };
    },
  },
  methods: {
 rowSelected(items) {
      if (items.length > 0) this.fzsID = items[0].FzsID;
      console.log(items);
      this.$emit("rowSelected", items);
  },

    refresh(item){
      this.$emit('refresh',item);
    },
    clearForm() {
      this.$refs.FzsDescription.focus();
    },
    saveDed(item) {
        item.SecStatus=1;
        if (item.FzsDescription.length == 0) {
        this.$fun.alert("Registre descripción de valor", "warning");
        return;
      }
      item.save();       
    },
  },
};
</script>
